var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row card mt-3"
  }, [_c('h3', {
    staticClass: "text-primary ml-3 mt-2"
  }, [_vm._v("All Review")]), _c('hr'), _c('div', {
    staticClass: "row p-3"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }), _c('div', {
    staticClass: "col-sm-4"
  }), _c('div', {
    staticClass: "col-sm-2"
  }, [_c('div', [_c('b-form-select', {
    attrs: {
      "id": "visibleId",
      "options": _vm.options
    },
    on: {
      "change": function ($event) {
        return _vm.getByVisible($event);
      }
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1)])]), _c('div', {
    staticClass: "ml-3 mr-3"
  }, [_c('TableReviews', {
    attrs: {
      "reviews": _vm.reviews,
      "isLoading": _vm.isLoading
    }
  })], 1), _c('b-row', {
    staticClass: "justify-content-end mr-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }