var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-table', {
    attrs: {
      "sticky-header": "600px",
      "responsive": "xl",
      "show-empty": "",
      "busy": _vm.isLoading,
      "hover": "",
      "isLoading": _vm.isLoading,
      "fields": _vm.fields,
      "items": _vm.reviews
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + row.index + 1) + " ")];
      }
    }, {
      key: "cell(username)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item ? row.item.username : '') + " ")];
      }
    }, {
      key: "cell(book)",
      fn: function (row) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: ("/items/detail/" + (row.item.core_item.id))
            }
          }
        }, [_vm._v(" " + _vm._s(row.item ? row.item.core_item.name : '') + " ")])];
      }
    }, {
      key: "cell(rating)",
      fn: function (row) {
        return [row.item.rating ? _c('div', _vm._l(row.item.rating, function (i) {
          return _c('span', {
            key: i
          }, [_c('span', {
            staticClass: "fa fa-star text-warning star-rating-size"
          })]);
        }), 0) : _c('div', [_vm._v(" " + _vm._s(row.item ? row.item.rating : '') + " ")])];
      }
    }, {
      key: "cell(review)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item ? row.item.review : '') + " ")];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.getTime(row.item.created)) + " ")];
      }
    }, {
      key: "cell(visible)",
      fn: function (row) {
        return [row.item.visible ? _c('div', [_c('span', {
          staticClass: "badge badge-pill badge-primary"
        }, [_vm._v(_vm._s(row.item.visible))])]) : _c('div', [_c('span', {
          staticClass: "badge badge-pill badge-danger"
        }, [_vm._v(_vm._s(row.item.visible))])])];
      }
    }, {
      key: "cell(approve)",
      fn: function (row) {
        return [row.item.visible ? _c('div', [_c('button', {
          staticClass: "btn btn-md btn-warning rounded",
          attrs: {
            "title": "Approved",
            "disabled": ""
          }
        }, [_vm._v(" Approved ")])]) : _c('div', [_c('button', {
          staticClass: "btn btn-md btn-success rounded",
          attrs: {
            "title": "Approve"
          },
          on: {
            "click": function ($event) {
              return _vm.approveReview(row.item.id);
            }
          }
        }, [_vm._v(" Approve ")])])];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }