<template>
  <div>
    <b-table
      sticky-header="600px"
      responsive="xl"
      show-empty
      :busy="isLoading"
      hover
      :isLoading="isLoading"
      :fields="fields"
      :items="reviews"
    >
      <template #cell(no)="row">
        {{ (currentPage - 1) * perPage + row.index + 1 }}
      </template>
      <template #cell(username)="row">
        {{ row.item ? row.item.username : '' }}
      </template>
      <template #cell(book)="row">
        <router-link :to="{ path: `/items/detail/${row.item.core_item.id}` }">
          {{ row.item ? row.item.core_item.name : '' }}
        </router-link>
      </template>
      <template #cell(rating)="row" class="text-center">
        <div v-if="row.item.rating">
          <span v-for="i in row.item.rating" :key="i">
            <span class="fa fa-star text-warning star-rating-size"></span>
          </span>
        </div>
        <div v-else>
          {{ row.item ? row.item.rating : '' }}
        </div>
      </template>
      <template #cell(review)="row">
        {{ row.item ? row.item.review : '' }}
      </template>
      <template #cell(created)="row">
        {{ getTime(row.item.created) }}
      </template>
      <template #cell(visible)="row">
        <div v-if="row.item.visible">
          <span class="badge badge-pill badge-primary">{{
            row.item.visible
          }}</span>
        </div>
        <div v-else>
          <span class="badge badge-pill badge-danger">{{
            row.item.visible
          }}</span>
        </div>
      </template>
      <template #cell(approve)="row">
        <div v-if="row.item.visible">
          <button
            class="btn btn-md btn-warning rounded"
            title="Approved"
            disabled
          >
            Approved
          </button>
        </div>
        <div v-else>
          <button
            class="btn btn-md btn-success rounded"
            title="Approve"
            @click="approveReview(row.item.id)"
          >
            Approve
          </button>
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-dark my-2">
          <b-spinner
            class="align-middle"
            variant="dark"
            type="grow"
            label="Loading .."
          >
          </b-spinner>
          Loading ..
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import API from '../../plugins/http';
import moment from 'moment';
export default {
  name: 'Reviews',
  props: {
    reviews: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 15,
      fields: [
        {
          key: 'no',
          label: 'No',
        },
        {
          key: 'username',
          label: 'Username',
        },
        {
          key: 'book',
          label: 'Book',
          thClass: 'text-capitalize',
          tdClass: 'text-capitalize',
        },
        {
          key: 'rating',
          label: 'Rating',
          thClass: 'text-capitalize',
          tdClass: 'text-capitalize',
          sortable: true,
        },
        {
          key: 'review',
          label: 'Review',
          thClass: 'text-capitalize',
          tdClass: 'text-capitalize',
        },
        {
          key: 'created',
          label: 'Created',
          thClass: 'text-capitalize',
          tdClass: 'text-capitalize',
          thStyle: { width: '15%' },
        },
        {
          key: 'visible',
          label: 'Visible',
          thClass: 'text-capitalize',
          tdClass: 'text-capitalize',
        },
        { key: 'approve', label: 'Action' },
      ],
    };
  },
  computed: {
    ...mapState({
      // isLoading: (state) => state.reviews.isLoading,
      isError: (state) => state.reviews.isError,
      totalRows: (state) => state.reviews.totalRows,
      // reviews: (state) => state.reviews.items,
    }),
  },
  methods: {
    ...mapActions('reviews', ['fetchReviews']),

    approveReview(id) {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
        visible: 'false',
      };
      this.$swal({
        title: 'Are you sure ?',
        text: `You will approve this review !`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          API.put('review/approve-review/' + id)
            .then(async (response) => {
              if (response.status === 200) {
                this.$swal({
                  icon: 'success',
                  toast: true,
                  position: 'top-end',
                  title: 'Review Has Been Successfully Approved',
                  timer: 5000,
                  text: this.message,
                });
              } else {
                this.$swal({
                  icon: 'error',
                  toast: true,
                  position: 'top-end',
                  title: "Something's Error",
                  timer: 3000,
                  text: this.message,
                });
              }
              this.fetchReviews(payload);
            })
            .catch((error) => {
              console.log('ini error', error);
              this.$swal({
                icon: 'error',
                toast: true,
                position: 'top-end',
                title: 'Failed To Approve This Review',
                timer: 3000,
                text: this.message,
              });
              this.fetchReviews(payload);
            });
        }
      });
    },

    getTime(time) {
      return moment(time).format('DD-MM-YYYY HH:mm:ss');
    },
  },
};
</script>

<style lang="css" scoped>
.star-rating-size {
  font-size: 10px;
}
</style>
