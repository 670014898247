<template>
  <div class="row card mt-3">
    <h3 class="text-primary ml-3 mt-2">All Review</h3>
    <hr />
    <div class="row p-3">
      <div class="col-sm-6"></div>
      <div class="col-sm-4"></div>
      <div class="col-sm-2">
        <div>
          <b-form-select
            id="visibleId"
            v-model="selected"
            :options="options"
            @change="getByVisible($event)"
          ></b-form-select>
        </div>
      </div>
    </div>
    <div class="ml-3 mr-3">
      <TableReviews :reviews="reviews" :isLoading="isLoading" />
    </div>
    <b-row class="justify-content-end mr-2">
      <b-col lg="3">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TableReviews from '../../components/review/table.vue';
export default {
  name: 'Reviews',
  components: {
    TableReviews,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 15,
      selected: 'false',
      options: [
        { value: 'true', text: 'APPROVED' },
        { value: 'false', text: 'NEED APPROVE' },
      ],
    };
  },
  watch: {
    currentPage: function() {
      this.getReviews();
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.reviews.isLoading,
      isError: (state) => state.reviews.isError,
      totalRows: (state) => state.reviews.totalRows,
      reviews: (state) => state.reviews.items,
    }),
  },
  mounted() {
    this.getReviews();
  },
  methods: {
    ...mapActions('reviews', ['fetchReviews']),
    getReviews() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
        visible: document.getElementById('visibleId').value,
      };
      this.fetchReviews(payload);
    },

    getByVisible(visible) {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
        visible: visible,
      };
      this.fetchReviews(payload);
    },
  },
};
</script>
